<template>
	<el-menu
		mode="vertical"
		:collapse="isCollapse"
		router
		active-text-color="#409EFF"
		:default-active="currentRoute"
	>
		<el-menu-item index="/user-list">
			<i class="el-icon-user"></i>
			<span slot="title">用户列表</span>
		</el-menu-item>
		<el-menu-item index="/admin-list">
			<i class="el-icon-ship"></i>
			<span slot="title">管理员列表</span>
		</el-menu-item>
		<el-menu-item index="/seat-list">
			<i class="el-icon-headset"></i>
			<span slot="title">坐席列表</span>
		</el-menu-item>
		<el-menu-item index="/merchant-list">
			<i class="el-icon-bank-card"></i>
			<span slot="title">商家列表</span>
		</el-menu-item>
		<el-menu-item index="/business-line">
			<i class="el-icon-s-promotion"></i>
			<span slot="title">业务线路</span>
		</el-menu-item>
		<el-menu-item index="/apply-list">
			<i class="el-icon-s-opportunity"></i>
			<span slot="title">应用列表</span>
		</el-menu-item>
		<el-menu-item index="/account-rule-list">
			<i class="el-icon-sunny"></i>
			<span slot="title">计费规则</span>
		</el-menu-item>
		<el-menu-item index="/account-list">
			<i class="el-icon-bank-card"></i>
			<span slot="title">计费账户</span>
		</el-menu-item>
<!--		<el-menu-item index="/call-rule">-->
<!--			<i class="el-icon-bell"></i>-->
<!--			<span slot="title">呼叫规则</span>-->
<!--		</el-menu-item>-->
<!--		<el-menu-item index="/call-check">-->
<!--			<i class="el-icon-truck"></i>-->
<!--			<span slot="title">语音质检</span>-->
<!--		</el-menu-item>-->
		<el-menu-item index="/call-record">
			<i class="el-icon-service"></i>
			<span slot="title">通话记录</span>
		</el-menu-item>
		<el-menu-item index="/seat-report">
			<icon-svg
				iconClass="wxbbaobiao"
				style="margin-right: 5px; font-size: 18px; color: #909399"
			></icon-svg>
			<span slot="title">呼叫系统坐席报表</span>
		</el-menu-item>
		<el-menu-item index="/merchant-report">
			<icon-svg
				iconClass="fl-baobiao"
				style="margin-right: 5px; font-size: 18px; color: #909399"
			></icon-svg>
			<span slot="title">呼叫系统商家报表</span>
		</el-menu-item>
	</el-menu>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex'
	export default {
		data() {
			return {
				sysindex: 'first',
				currentRoute: this.$route.path,
				defaultOpenArr: [],
			}
		},
		computed: {
			...mapGetters(['sidebar']),
			isCollapse: function () {
				return !this.sidebar.opened
			},
		},
		created() {},
		methods: {
			selectHandler(index) {
				this.setMenu(index)
			},
		},
	}
</script>

<style lang="scss" scoped></style>
