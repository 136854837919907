const getters = {
  token: state => state.user.token,
  session: state => state.user.session,
  adminName: state => state.user.adminName,
  adminId: state => state.user.adminId,
  phone: state => state.user.phone,
  platformName: state => state.user.platformName,
  sidebar: state => state.app.sidebar,
};
export default getters
