import fetch from '@/utils/fetch';

/**
 * 初始化外呼坐席列表
 * @param params
 */
export function login(params) {
  return fetch({
    url: '/user/admin/login',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getUserListByPage(params) {
  return fetch({
    url: '/user/getUserListByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createUser(params) {
  return fetch({
    url: '/user/createUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editUser(params) {
  return fetch({
    url: '/user/editUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editUserState(params) {
  return fetch({
    url: '/user/editUserState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantListByPage(params) {
  return fetch({
    url: '/merchant/getMerchantListByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createMerchant(params) {
  return fetch({
    url: '/merchant/createMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editMerchant(params) {
  return fetch({
    url: '/merchant/editMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatAccountList(params) {
  return fetch({
    url: '/seat/account/getSeatAccountList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createSeatAccount(params) {
  return fetch({
    url: '/seat/account/createSeatAccount',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editSeatAccount(params) {
  return fetch({
    url: '/seat/account/editSeatAccount',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initSeatAccount(params) {
  return fetch({
    url: '/seat/account/initSeatAccount',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getUserListNoBindSeat(params) {
  return fetch({
    url: '/seat/account/getUserListNoBindSeat',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editSeatAccountState(params) {
  return fetch({
    url: '/seat/account/editSeatAccountState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteSeat(params) {
  return fetch({
    url: '/seat/account/deleteSeat',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteUser(params) {
  return fetch({
    url: '/user/deleteUser',
    method: 'POST',
    data: {
      ...params
    }
  });
}


export function deleteMerchant(params) {
  return fetch({
    url: '/merchant/deleteMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getApplyListByPage(params) {
  return fetch({
    url: '/apply/getApplyListByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editApply(params) {
  return fetch({
    url: '/apply/editApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createApply(params) {
  return fetch({
    url: '/apply/createApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteApplyState(params) {
  return fetch({
    url: '/apply/deleteApplyState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editApplyState(params) {
  return fetch({
    url: '/apply/editApplyState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initApply(params) {
  return fetch({
    url: '/apply/initApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantInfo(params) {
  return fetch({
    url: '/merchant/getMerchantInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editCustomerRecoveryType(params) {
  return fetch({
    url: '/merchant/editCustomerRecoveryType',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantUserRaListByPage(params) {
  return fetch({
    url: '/merchant/getMerchantUserRaListByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantApplyRaList(params) {
  return fetch({
    url: '/merchant/getMerchantApplyRaList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteMerchantUserRa(params) {
  return fetch({
    url: '/merchant/deleteMerchantUserRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteMerchantApplyRa(params) {
  return fetch({
    url: '/merchant/deleteMerchantApplyRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getApplyList(params) {
  return fetch({
    url: '/apply/getApplyList',
    method: 'POST',
    data: {
      ...params
    }
  });
}


export function saveMerchantApplyRa(params) {
  return fetch({
    url: '/merchant/saveMerchantApplyRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function saveMerchantUserRa(params) {
  return fetch({
    url: '/merchant/saveMerchantUserRa',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getMerchantNoPage(params) {
  return fetch({
    url: '/merchant/getMerchantNoPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatAccountReportListOfAdmin(params) {
  return fetch({
    url: '/report/getSeatAccountReportListOfAdmin',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getSeatMerchantReportListOfAdmin(params) {
  return fetch({
    url: '/report/getSeatMerchantReportListOfAdmin',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createAccountRule(params) {
  return fetch({
    url: '/rule/createAccountRule',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function editAccountRule(params) {
  return fetch({
    url: '/rule/editAccountRule',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getAccountRuleList(params) {
  return fetch({
    url: '/rule/getAccountRuleList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getAccountRuleListNoPage(params) {
  return fetch({
    url: '/rule/getAccountRuleListNoPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function listBillingAcctByPage(params) {
  return fetch({
    url: '/bill/listBillingAcctByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createBillingAcct(params) {
  return fetch({
    url: '/bill/createBillingAcct',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function updateBillingAcct(params) {
  return fetch({
    url: '/bill/updateBillingAcct',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function listBillingAcct(params) {
  return fetch({
    url: '/bill/listBillingAcct',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initAddTopUp(params) {
  return fetch({
    url: '/merchant/initAddTopUp',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function addTopUp(params) {
  return fetch({
    url: '/merchant/addTopUp',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function listTopUpByPage(params) {
  return fetch({
    url: '/merchant/listTopUpByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function listCallRecordByPlatform(params) {
  return fetch({
    url: '/record/listCallRecordByPlatform',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function listUserAdminByPage(params) {
  return fetch({
    url: '/user/listUserAdminByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function updateUserAdminState(params) {
  return fetch({
    url: '/user/updateUserAdminState',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createUserAdmin(params) {
  return fetch({
    url: '/user/createUserAdmin',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function updateUserAdmin(params) {
  return fetch({
    url: '/user/updateUserAdmin',
    method: 'POST',
    data: {
      ...params
    }
  })
}

export function deleteUserAdmin(params) {
  return fetch({
    url: '/user/deleteUserAdmin',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function exportSeatAccountList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/seatAccountList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '坐席列表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}

export function createBusinessLine(params) {
  return fetch({
    url: '/businessline/createBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function updateBusinessLine(params) {
  return fetch({
    url: '/businessline/updateBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function updateBusinessLineState(params) {
  return fetch({
    url: '/businessline/updateBusinessLineState',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function createBusinessLineApply(params) {
  return fetch({
    url: '/businessline/createBusinessLineApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function updateBusinessLineApply(params) {
  return fetch({
    url: '/businessline/updateBusinessLineApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function getBusinessLine(params) {
  return fetch({
    url: '/businessline/getBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function listBusinessLineByPage(params) {
  return fetch({
    url: '/businessline/listBusinessLineByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function listBusinessLine(params) {
  return fetch({
    url: '/businessline/listBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function listByApplyRaIdByPage(params) {
  return fetch({
    url: '/businessline/listByApplyRaIdByPage',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function addApplyMiddleNumber(params) {
  return fetch({
    url: '/businessline/addApplyMiddleNumber',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function updateApplyMiddleNumber(params) {
  return fetch({
    url: '/businessline/updateApplyMiddleNumber',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function deleteApplyMiddleNumber(params) {
  return fetch({
    url: '/businessline/deleteApplyMiddleNumber',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function listMerchantBusinessLine(params) {
  return fetch({
    url: '/businessline/listMerchantBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function deleteMerchantBusinessLine(params) {
  return fetch({
    url: '/businessline/deleteMerchantBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function addMerchantBusinessLine(params) {
  return fetch({
    url: '/businessline/addMerchantBusinessLine',
    method: 'POST',
    data: {
      ...params
    }
  });
}


export function deleteBusinessLineApply(params) {
  return fetch({
    url: '/businessline/deleteBusinessLineApply',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getLineResourcesList(params) {
  return fetch({
    url: '/merchant/getLineResourcesList',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function initMerchantLineInfo(params) {
  return fetch({
    url: '/merchant/initMerchantLineInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function createLineResourcesInfo(params) {
  return fetch({
    url: '/merchant/createLineResourcesInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function updateLineResourcesInfo(params) {
  return fetch({
    url: '/merchant/updateLineResourcesInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function deleteLineResourcesInfo(params) {
  return fetch({
    url: '/merchant/deleteLineResourcesInfo',
    method: 'POST',
    data: {
      ...params
    }
  });
}

export function getOpenApiInfoOfMerchant(params) {
  return fetch({
    url: '/merchant/getOpenApiInfoOfMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function saveOpenApiInfoOfMerchant(params) {
  return fetch({
    url: '/merchant/saveOpenApiInfoOfMerchant',
    method: 'POST',
    data: {
      ...params
    }
  });
}
export function exportRecordList(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/exportRecordList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '通话记录报表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}

export function exportSeatAccountReport(params) {
  const data = new FormData()
  for (const key in params) {
    if (params[key]) {
      data.append(key, params[key])
    }
  }
  return fetch({
    url: '/export/exportAdminSeatAccountReportList',
    method: 'POST',
    responseType: 'blob',
    data: data
  }).then(res => {
    let blob = new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" }); // 为blob设置文件类型，这里以.xlsx为例
    let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
    let a = document.createElement("a");
    a.href = url;
    a.download = '坐席报表';
    a.click();
    // 释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  })
}