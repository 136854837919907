import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404/notfound.vue'
import Layout from '../views/layout/Layout.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [


  {
    path: '/',
    name: '',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      { path: '/user-list', name: 'user-list', component: () => import('../views/user/index.vue') },
      { path: '/admin-list', name: 'admin-list', component: () => import('../views/admin/index.vue') },
      { path: '/seat-list', name: 'seat-list', component: () => import('../views/seat/index.vue') },
      { path: '/merchant-list', name: 'merchant-list', component: () => import('../views/merchant/index.vue') },
      {
        path: '/merchant-details',
        name: 'merchant-details',
        component: () => import('../views/merchant/details.vue')
      },
      { path: '/apply-list', name: 'apply-list', component: () => import('../views/apply/index.vue') },
      {
        path: '/seat-report',
        name: 'seat-report',
        component: () => import('../views/report/seat-report.vue')
      },
      {
        path: '/merchant-report',
        name: 'merchant-report',
        component: () => import('../views/report/merchant-report.vue')
      },
      {
        path: '/account-rule-list',
        name: 'account-rule-list',
        component: () => import('../views/account-rule/index.vue')
      },
      {
        path: '/account-list',
        name: 'account-list',
        component: () => import('../views/account/index.vue')
      },
      {
        path: '/call-record',
        name: 'call-record',
        component: () => import('../views/call-record.vue')
      },
      {
        path: '/business-line',
        name: 'business-line',
        component: () => import('../views/business-line/index')
      },
      {
        path: '/business-line-details',
        name: 'business-line-details',
        component: () => import('../views/business-line/details')
      },
    ]
  },
  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/login', name: 'login', component: () => import('../views/login.vue') },
  { path: '*', redirect: '/404', hidden: true },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
