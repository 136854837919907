<template>
  <el-card :body-style="{ padding: '10px'}">
    <section class="app-main">
      <transition mode="out-in">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition mode="out-in">
        <router-view :key="key" v-if="!$route.meta.keepAlive"></router-view>
      </transition>
    </section>
  </el-card>
  <!--<navbar></navbar>-->
</template>

<script>
import { Navbar } from "@/views/layout";

export default {
  name: "AppMain",
  data() {
    return {
      showTransition: false,
    };
  },
  components: {
    Navbar,
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 90px);
}
.coryright {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 12px;
  color: #999;
  z-index: 998;
  line-height: 40px;
  background-color: #e4e4e4;
}

.v-enter-active {
  transition: all 1s ease;
}
.v-leave-active {
  transition: all 0s ease;
}
.v-enter,
.v-leave {
  opacity: 0;
}
</style>
