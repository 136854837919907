import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/assets/iconfont/iconfont'
import IconSvg from '@/components/Icon-svg/index.vue'
Vue.component('icon-svg', IconSvg);
Vue.config.productionTip = false




const whiteList = ['/login'];
router.beforeEach(async (to, from, next) => {
  if (store.getters.adminId) {
    if (to.path == '/login') {
      next({ path: '/' });
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login');
    }
  }

}
)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
