<template>
  <div class="grid-content">
    <div class="logo">
      <!-- <img :src="img" alt="" /> -->
      <span class="head-title" @click="linktoIndex">{{platformName}}</span>
      <hamburger
        class="hamburger-container"
        :toggleClick="toggleSideBar"
        :isActive="sidebar.opened"
      ></hamburger>
    </div>

    <div class="top-info">
      <!-- <el-tooltip content="查看个人资料" placement="top">
        <el-button
          type="text"
          style="color: white; font-size: 18px; margin-right: 20px"
          @click="linkToAccount(id)"
          >{{ adminName }}</el-button
        >
      </el-tooltip> -->

      <span style="color: white; font-size: 18px; margin-right: 20px">{{adminName}}</span>
      <el-tooltip content="退出系统" placement="top">
        <el-button
          type="text"
          style="color: white; font-size: 18px; margin-right: 20px"
          @click="logout"
          >退出</el-button
        >
      </el-tooltip>
    </div>
  </div>
</template>

<script>
// import {  AppMain } from '@/views/layout';
import "element-ui/lib/theme-chalk/display.css";
import { mapGetters, mapMutations } from "vuex";
import Hamburger from "@/components/Hamburger";
import Vue from "vue";
export default {
  name: "topbar",
  data() {
    return {
      messageCount: 0,
      messageData: [],
      id: "",
      img: require("@/assets/images/logo.jpg"),
    };
  },
  components: {
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar", "adminName","platformName"]),
  },
  methods: {

    linktoIndex() {
      this.$router.push("/");
    },
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$confirm(`确定退出吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("FedLogOut").then((data) => {
            console.log(data);
            location.reload();
          });
        })
        .catch(() => {});
    },
    clickTab(key) {
      this.setModule(key);
    },
    linkToAccount(id) {
      this.$router.push(`/account/${id}`);
    },
  },
  watch: {},
  created() {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.grid-content {
  background: #000;
  height: 60px;
  color: white;
  display: flex;
  justify-content: space-between;
  line-height: 60px;
  .hamburger-container {
    margin: 0px 20px ;
  }
  .logo {
    padding-left: 20px;
    font-size: 30px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    .head-title{
      display: inline-block;
      cursor: pointer;
      border-radius: 4px;
      padding: 0 10px;
      user-select: none;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
  .svg-container {
    flex: 0;
    font-size: 20px;
  }
  .top-logo {
    width: 264px;
    height: 80px;
    text-align: center;
    line-height: 80px;
  }
  .top-logo img {
    width: 60px;
    vertical-align: middle;
  }
}
</style>
