import { login } from '@/api';
// import {getUserInfo,getInfo} from '@/api/user/index';
import Cookies from 'js-cookie';

const user = {
  state: {
    token: localStorage.getItem('token'),
    satoken: localStorage.getItem('token'),
    session: {},
    adminName: localStorage.getItem('adminName'),
    adminId: localStorage.getItem('adminId'),
    phone: localStorage.getItem('phone'),
    platformName: localStorage.getItem('platformName')
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem('token', token)
    },
    SET_SATOKEN: (state, satoken) => {
      state.satoken = satoken;
      localStorage.setItem('token', satoken)
    },
    SET_ADMIN_NAME: (state, name) => {
      state.adminName = name;
      localStorage.setItem('adminName', name)
    },
    SET_ADMIN_ID: (state, name) => {
      state.adminId = name;
      localStorage.setItem('adminId', name)
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone;
      localStorage.setItem('phone', phone)
    },
    SET_PLATFORMNAME: (state, name) => {
      state.platformName = name
      localStorage.setItem('platformName', name)
    },
    SET_SESSION: (state, session) => {
      state.session = session
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const phone = userInfo.phone.trim();
      return new Promise((resolve, reject) => {
        // phone,userInfo.userPwd,userInfo.typeId
        login({
          userName: phone,
          password: userInfo.userPwd
        }).then(response => {
          console.log('response====', response)
          commit('SET_SESSION', response);
          commit('SET_TOKEN', response.token);
          commit('SET_ADMIN_NAME', response.adminName);
          commit('SET_ADMIN_ID', response.adminId);
          commit('SET_PHONE', response.phone);
          commit('SET_PLATFORMNAME', response.platformName);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },


    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo({ "token": state.token }).then(data => {
          if (data) {
            commit('SET_SESSION', data.session);
            commit('SET_ROLES', data.roleList);
            Cookies.set('lastLoginMerchantId', data.session.lastLoginMerchantId);
            Cookies.set('lastLoginMerchantName', data.session.lastLoginMerchantName);
            commit('SET_NAME', data.session.userSessionDto.userName);
            resolve(data);
          } else {
            Cookies.remove("Admin-Token");
            Cookies.remove("currentModule");
            Cookies.remove("currentMenu");
            commit('SET_TOKEN', '');
            resolve();
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '');
          localStorage.clear();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    // 前端 登出
    FedLogOut({ commit, state }) {
      return new Promise(resolve => {
        localStorage.clear();
        console.log('state=========', state)
        resolve(state);
      });
    }
  }
};

export default user;
